body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 

}

.spinner h1 {
  margin-bottom: 20px;
}


.csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.react-pdf__Page__textContent {
  display: none;
}

.circle {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: green;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
}

.stepWrapper {
  display: flex;
  margin-bottom: 20px;
}

.stepBlock .circleWrapper {
  padding: 0px, 20px;
}

.selected circle {
  color: black;
  background-color: white;
  border-color: green;
}

.ant-layout-header.header-0-2-3 {
  justify-content: space-between;
}
@media (min-width: 992px) {
  .ant-table-pagination-right {
    justify-content: center !important;
  }
}
@media (max-width: 991px) {
  .logo-header-part {
    display: flex;
    font-size: 16px;
    margin-right: 5px;
    line-height: normal;
    align-items: center;
  }

  .button-admin-div button {
    margin-bottom: 10px;
    min-width: 96px;
  }

  .ant-row{
    display: block !important;
  }

  [class*="ant-col-"] {
    max-width: 100% !important;
  }

  .ant-checkbox-wrapper{
    width: 100%;
  }
}
.log-table table {
  table-layout: fixed !important;
  width: 100% !important;
}

@media (max-width: 576px) {
  .pagination-mob .ant-pagination-options {
    display: inline-block !important;
    margin-top: 5px;
  }
}

@media (max-width: 767px) {
  .searchbox-impersonation .ant-input-group .ant-input-group-addon button {
    height: 25.5px;
  }
  .searchbox-impersonation span input {
    font-size: x-small;
  }
  .impersonation-table table {
    table-layout: fixed !important;
    font-size: x-small;
  }
  .impersonation-table .ant-table-title {
    font-size: x-small;
  }
  .impersonation-table .ant-table-cell {
    padding: 3px;
  }
  .impersonation-table .ant-table-cell button {
    padding: 3px;
    height: 20px;
    font-size: x-small;
  }
  .searchbox-impersonation {
    width: auto !important;
  }
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column !important;
  }
  .workflow-step button.ant-btn.ant-btn-primary span {
    white-space: normal;
    font-size: 14px;
  }
  .workflow-step button.ant-btn.ant-btn-primary {
    height: auto !important;
    /* margin-top: 10px; */
  }
  .Navigation-bar
    .ant-drawer-content-wrapper
    .ant-drawer-content
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .ant-menu-vertical {
    border-right: none !important;
  }

  .logo-header-part .span-text {
    font-weight: 200;
  }
  .ant-layout-header.header-0-2-3 {
    line-height: 10px !important;
  }
  .ant-menu-sub .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: black !important;
  }
  .ant-menu-sub .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
  }
  .impersonate-icon {
    margin-top: 10px;
  }
  .impersonate-icon-div {
    /* height:21px; */
    padding-left: 10px;
  }
  .impersonate-icon .icon-link {
    height: 30px;
    width: 30px;
  }
  .span-text-nowrap {
    font-size: 10px;
    white-space: nowrap;
  }
  .span-text {
    font-size: 10px;
  }
  .search-section {
    flex-direction: column !important;
  }
  .sub-search-section {
    width: 85% !important;
  }
  /* .pagination-mob li {
    height: 20px;
    width: 20px;
  } */
  .smaller-button {
    transform: scale(0.7);
  }
  .button-rpe {
    flex-direction: column !important;
  }
  .button-rpe-search {
    width: 100% !important;
  }
  .button-rpe-cascader {
    width: auto !important;
    margin-bottom: 10px !important;
  }
  .button-rpe-datepicker {
    width: auto !important;
  }
  .header-class-right {
    padding: 0 !important;
  }
}
.header-class-right {
  padding: 0 12px;
}
.button-rpe {
  display: flex;
  flex-direction: row;
}
.button-rpe-search,
.button-rpe-cascader,
.button-rpe-datepicker {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

/* .button-rpe-search{
  float: left;
  width: 35% !important;
  margin-right: 10px;
  margin-bottom: 10px;
}
.button-rpe-cascader{
  width:20%;
  margin-bottom: 10px;
}
.button-rpe-datepicker{
  float: right;
  margin-bottom: 10px;
  width:40%;
} */
.search-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row;
}
.sub-search-section {
  padding: 5px;
  width: 35%;
  max-width: 400px;
}
.our-card-main {
  max-width: 200px;
  display: flex;
  flex-direction: column;
}
.row-in-workflow {
  margin: 0 !important;
}
.row-in-workflow-div {
  max-width: 100% !important;
  padding: 10px;
}
.row-in-workflow-div-else {
  min-width: 200px;
}

.ant-list.ant-list-split.list-mobile-view .ant-spin-nested-loading .ant-spin-container .ant-list-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
}
.ant-list.ant-list-split.list-mobile-view .ant-spin-nested-loading .ant-spin-container .ant-list-items .ant-list-item {
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  margin: 10px;
}
.ant-list.ant-list-split.list-mobile-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta {
  max-width: 100%;
  display: block;
  margin-bottom: 5px;
}
.ant-list.ant-list-split.list-mobile-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-content {
  /* flex: 1 0; */
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
}
.workflow-outer-div {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.workflow-outer-div span {
  padding-right: 8px;
}
.workfile-name-and-date {
  display: flex;
  flex-direction: column;
}
.small-light-span {
  font-size: smaller;
  font-weight: lighter;
}
.list-mobile-view .ant-list-pagination {
  text-align: center !important;
}

.ant-list.ant-list-split.list-mobile-view
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-content
  h4.ant-list-item-meta-title {
  white-space: break-spaces;
}

.our-card-main .ant-card-cover {
  margin: 0 !important;
}
.our-card-main .ant-card-body {
  padding: 8px !important;
  min-height: 70px;
}

.our-card-main .ant-card-body label {
  position: relative !important;
}
