.previewBtnStyle {
    background-color: #990000;
}

.previewBtnStyle:hover {
    background-color: #990000;
}

.previewBtnStyle:focus {
    background-color: #990000;
}